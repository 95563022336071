var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "ruploadcompletealertcard pa-0 pt-1 box-shadow-custom",
    attrs: {
      "ripple": false,
      "rounded": false,
      "max-width": "240"
    }
  }, [_c('div', {
    staticClass: "px-4 py-2"
  }, [_c('div', {
    staticClass: "ruploadcompletealertcard--header d-flex py-1"
  }, [_c('v-icon', {
    staticClass: "ruploadcompletealertcard--icon"
  }, [_vm._v(" mdi-tray-arrow-up ")]), _c('span', {
    staticClass: "ruploadcompletealertcard--title text-truncate text-h6 ml-2"
  }, [_vm._v(" \"" + _vm._s(_vm.uploadName) + "\" upload completed ")])], 1), _c('div', {
    staticClass: "ruploadcompletealertcard--text d-flex"
  }, [_c('span', {
    staticClass: "rsearchcard--text"
  }, [_vm._v("Upload is completed")])]), _c('div', {
    staticClass: "ruploadcompletealertcard--progress d-flex my-2 d-flex align-center"
  }, [_c('v-progress-linear', {
    attrs: {
      "value": _vm.progressPercent,
      "color": "#00A682",
      "height": "15"
    }
  }), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s(_vm.progressPercent) + "%")])], 1), _c('div', {
    staticClass: "ruploadcompletealertcard--link d-flex"
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v(_vm._s(_vm.$t('alerts.viewResults')))])]), _vm.showHideIcon ? _c('mark-as-seen', {
    attrs: {
      "show-hide-icon": _vm.showHideIcon
    },
    on: {
      "on:mark-as-seen": _vm.onMarkAsSeen
    }
  }) : _vm._e(), _c('v-divider', {
    staticClass: "my-3"
  }), _c('div', {
    staticClass: "ruploadcompletealertcard--footer d-flex justify-space-between"
  }, [_c('span', {
    staticClass: "date-title"
  }, [_vm._v("Date Upload")]), _c('span', {
    staticClass: "date-content"
  }, [_vm._v(_vm._s(_vm.date))])])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }