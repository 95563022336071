var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "rnoncanonicalalertcard pa-0 pt-1 box-shadow-custom",
    attrs: {
      "ripple": false,
      "rounded": false,
      "max-width": "240"
    }
  }, [_c('div', {
    staticClass: "px-4 py-2"
  }, [_c('div', {
    staticClass: "rnoncanonicalalertcard--header d-flex py-1"
  }, [_c('v-icon', {
    staticClass: "rnoncanonicalalertcard--icon"
  }, [_vm._v(" mdi-alert-circle ")]), _c('span', {
    staticClass: "rnoncanonicalalertcard--title text-truncate text-h6 ml-2"
  }, [_vm._v(" URLs replaced ")])], 1), _c('div', {
    staticClass: "rnoncanonicalalertcard--text d-flex"
  }, [_c('span', {
    staticClass: "rnoncanonicalalertcard--text"
  }, [_vm._v(" URL "), _c('a', {
    attrs: {
      "href": _vm.nonCanonicalUrl,
      "title": _vm.nonCanonicalUrl,
      "target": "_blank"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.abbrevNonCanonicalUrl)
    }
  }), _vm._v(" has been replaced with the canonical URL "), _c('a', {
    attrs: {
      "href": _vm.canonicalUrl,
      "title": _vm.canonicalUrl,
      "target": "_blank"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.abbrevCanonicalUrl)
    }
  })])]), _vm.showHideIcon ? _c('mark-as-seen', {
    attrs: {
      "show-hide-icon": _vm.showHideIcon
    },
    on: {
      "on:mark-as-seen": _vm.onMarkAsSeen
    }
  }) : _vm._e(), _c('v-divider', {
    staticClass: "my-3"
  }), _c('div', {
    staticClass: "rnoncanonicalalertcard--footer d-flex justify-space-between"
  }, [_c('span', {
    staticClass: "date-title"
  }, [_vm._v("Date")]), _c('span', {
    staticClass: "date-content"
  }, [_vm._v(_vm._s(_vm.date))])])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }