var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "rsearchnewfoundsalertcard pa-0 pt-1 box-shadow-custom",
    attrs: {
      "ripple": false,
      "rounded": false,
      "max-width": "240"
    }
  }, [_c('div', {
    staticClass: "px-4 py-2"
  }, [_c('div', {
    staticClass: "rsearchnewfoundsalertcard--header d-flex py-1"
  }, [_c('v-icon', {
    staticClass: "rsearchnewfoundsalertcard--icon"
  }, [_vm._v(" mdi-new-box ")]), _c('span', {
    staticClass: "rsearchnewfoundsalertcard--title text-truncate text-h6 ml-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('alerts.newFounds.title')) + " ")])], 1), _c('div', {
    staticClass: "rsearchnewfoundsalertcard--text d-flex"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('alerts.newFounds.message', {
    numFounds: _vm.numFounds,
    searchName: _vm.searchName
  })) + " ")])]), _c('div', {
    staticClass: "rsearchnewfoundsalertcard--link d-flex mt-2"
  }, [_c('a', {
    on: {
      "click": _vm.onViewResultClick
    }
  }, [_vm._v(_vm._s(_vm.$t('alerts.viewResults')))])]), _vm.showHideIcon ? _c('mark-as-seen', {
    attrs: {
      "show-hide-icon": _vm.showHideIcon
    },
    on: {
      "on:mark-as-seen": _vm.onMarkAsSeen
    }
  }) : _vm._e(), _c('v-divider', {
    staticClass: "my-3"
  }), _c('div', {
    staticClass: "rsearchnewfoundsalertcard--footer d-flex justify-space-between"
  }, [_c('span', {
    staticClass: "date-title"
  }, [_vm._v(_vm._s(_vm.$t('alerts.date')))]), _c('span', {
    staticClass: "date-content"
  }, [_vm._v(_vm._s(_vm.date))])])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }