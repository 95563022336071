var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rchip"
  }, [_c('v-chip', {
    ref: "rchip--vchip",
    staticClass: "rchip--chip",
    class: {
      circle: _vm.circle,
      light: _vm.light,
      disabled: _vm.disabled,
      'v-chip--active': _vm.active,
      hasIcon: _vm.icon !== ''
    },
    style: _vm.widthStyle,
    attrs: {
      "ripple": false,
      "removable": _vm.removable,
      "outlined": _vm.outlined,
      "textonly": _vm.textonly
    },
    on: {
      "click": _vm.onClick
    }
  }, [_vm.width ? _c('div', {
    staticClass: "text-truncate",
    style: _vm.widthStyle,
    attrs: {
      "title": _vm.title || _vm.label
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]) : _c('span', {
    staticClass: "text-truncate",
    attrs: {
      "title": _vm.title || _vm.label
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]), _vm.icon ? _c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.icon) + " ")]) : _vm._e(), _vm.removable === true && _vm.textonly === false ? _c('v-icon', {
    staticClass: "rchip--remove-icon",
    attrs: {
      "data-testid": "icon",
      "small": ""
    }
  }, [_vm._v(" close ")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }